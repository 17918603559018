import { MultipleSelectorOption } from "@sourceful/design-system-v3";
import { z } from "zod";

export enum FormStep {
  Define = 1,
  Design = 2,
  Finalise = 3,
  Thanks = 4,
}

export type GenerateForm = {
  // Step 1
  product_brief: string;
  brand_name: string;
  packaging_type: string;
  // Step 2
  aesthetic: string;
  colour_desc: string;
  // Step 3
  customer_name: string;
  user_email: string;
  // Submission
  samples: number;
  debug: boolean;
  token: string;
};

export interface GenerateFormInput {
  product_brief: string;
  brand_name: string;
  aesthetic: string;
  colour_desc: string;
  packaging_type: string;
  user_email: string;
  customer_name: string;
  consent: boolean;
  samples: number;
  debug: boolean;
  token: string;
}

export const formSchemaStep1 = {
  product_brief: "",
  brand_name: "",
  packaging_type: "",
};

export const zodSchemaStep1 = z.object({
  product_brief: z
    .string()
    .min(1, "Product brief is required")
    .max(128, "Product brief can be no longer than 128 characters"),
  brand_name: z
    .string()
    .min(1, "Company name is required")
    .max(128, "Company name can be no longer than 128 characters"),
  packaging_type: z
    .string()
    .min(1, "Preferred packaging type is required")
    .max(128, "Preferred packaging type can be no longer than 128 characters"),
});

const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
  disable: z.boolean().optional(),
});

export const formSchemaStep2: {
  aesthetic: MultipleSelectorOption[];
  colour_desc: MultipleSelectorOption[];
} = {
  aesthetic: [],
  colour_desc: [],
};

export const zodSchemaStep2 = z.object({
  asthetic: z.array(optionSchema).min(1, "Brand aesthetic is required"),
  colour_desc: z.array(optionSchema).min(1, "Brand colours is required"),
});

export const formSchemaStep3 = {
  customer_name: "",
  user_email: "",
};

export const zodSchemaStep3 = z.object({
  customer_name: z.string().min(1, "Your name is required"),
  user_email: z.string().email("Invalid email"),
});
